// Plugins
import i18n from '@/plugins/i18n'

export default function(user) {
    let reportTypes = [];

    // Missing Documents
    reportTypes.push({
        value: "missing-documents-report",
        text: '1- '+i18n.t("missing_documents_report"),
        permission: "studentprogram_missingdocumentexcelexport",
    });

    // Notes Report
    reportTypes.push({
        value: "student-notes-report",
        text: '2- '+i18n.t("student_notes_report"),
        permission: "student_studentnotesreport",
    });

    // Full Scholarship
    reportTypes.push({
        value: "full-scholarship-students",
        text: '3- '+i18n.t("full_scholarship_students_enroll"),
        permission: "student_studentnotesreport",
    });

    reportTypes.push({
        value: "registration-numbers-by-faculty",
        text: '4- '+i18n.t("registration_numbers_by_faculty_report"),
        permission: "studentreport_registrationnumbersbyfaculty",
    });

    reportTypes.push({
        value: "number-of-students-by-status",
        text: '5- '+i18n.t("number_of_students_by_status_report"),
        permission: "studentreport_registrationnumbersbyfaculty",
    });

    // YOK Registration Status Report
    reportTypes.push({
        value: "yok-registration-status-report",
        text: '6- '+i18n.t("yok_registration_status_report"),
        permission: "program_registrationstatusreport",
    });

    reportTypes.push({
        value: "exam-overlap-report",
        text: '7- '+i18n.t("exam_overlap_report"),
        permission: "report_studentcollection",
    });

    reportTypes.push({
        value: "summer-school-report",
        text: '8- '+i18n.t("summer_school_report"),
        permission: "section_summerschoolreport",
    });

    reportTypes.push({
        value: "students-should-take-course",
        text: '9- '+i18n.t("students_should_take_course"),
        permission: "studentslot_studentsshouldtakecourse",
    });

    reportTypes.push({
        value: "students-should-take-elective",
        text: '10- '+i18n.t("students_should_take_elective"),
        permission: "studentslot_studentsshouldtakeelective",
    });

    reportTypes.push({
        value: "semester-status-analysis",
        text: '11- '+i18n.t("semester_status_analysis"),
        permission: "section_summerschoolreport",
    });
    reportTypes.push({
        value: "double-major-termination-report",
        text: '12- '+i18n.t("double_major_termination_report"),
        permission: "doublemajor_leavewithconsentreport",
    });
    reportTypes.push({
        value: "double-major-dismissal-report",
        text: '13- '+i18n.t("minor_application_leave_with_consent_report"),
        permission: "minorapplication_leavewithconsentreport",
    });
    reportTypes.push({
        value: "student-overlap-report",
        text: '14- '+i18n.t("student_overlap_report"),
        permission: "courseschedule_studentcoursescheduleoverlapreport",
    });
    reportTypes.push({
        value: "minor-applications-yok-report",
        text: '15- '+i18n.t("minor_applications_yok_report"),
        permission: "minorapplication_yokreport",
    });
    reportTypes.push({
        value: "undergraduate-transfer-yok-report",
        text: '16- '+i18n.t("undergraduate_transfer_yok_report"),
        permission: "undergraduatetransfer_yokreport",
    });
    reportTypes.push({
        value: "double-major-yok-report",
        text: '17- '+i18n.t("double_major_yok_report"),
        permission: "doublemajor_yokreport",
    });

    reportTypes.push({
        value: "students-should-take-courses",
        text: '18- '+i18n.t("students_should_take_courses"),
        permission: "studentslot_studentsshouldtakecourses",
    });

    reportTypes.push({
        value: "verticalTransition",
        text: '19- '+i18n.t("vertical_transition_and_vertical_transition_supplementary"),
        permission: "registration_verticaltransferyokreport",
    });

    reportTypes.push({
        value: "minor-branch-dismissal",
        text: '20- '+i18n.t("minor_branch_dismissal_report_for_not_registering_for_courses"),
        permission: "minorapplication_coursenotregistrationreport",
    });

    reportTypes.push({
        value: "major-graduate-minor-branch",
        text: '21- '+i18n.t("major_graduate_minor_branch_dismissal_report"),
        permission: "minorapplication_majoragraduatemajoryactivereport",
    });

    reportTypes.push({
        value: "education-information-reporting",
        text: '22- '+i18n.t("education_information_reporting"),
        permission: "studenteducation_excelreport",
    });

    reportTypes.push({
        value: "student-status-changes",
        text: '23- '+i18n.t("student_status_changes"),
        permission: "studentreport_statuschanges",
    });
    reportTypes.push({
        value: "discharge-report",
        text: '24- '+i18n.t("discharge_report_for_those_who_have_not_enrolled_in_courses"),
        permission: "studentreport_statuschanges",
    });
    reportTypes.push({
        value: "double-major-leave-with-consent-report",
        text: '25- '+i18n.t("major_major_dismissal_report"),
        permission: "studentreport_statuschanges",
    });
    reportTypes.push({
        value: "student-numbers-by-nationality-and-faculty",
        text: '26- '+i18n.t("student_numbers_by_nationality_and_faculty"),
        permission: "studentreport_studentnumbersbynationalityandfaculty",
    });
    reportTypes.push({
        value: "table-11-yok-report",
        text: '27- '+i18n.t("table_11_yok_report"),
        permission: "undergraduatetransfer_table11yokreport",
    });
    reportTypes.push({
        value: "table-11b-yok-report",
        text: '28- '+i18n.t("table_11b_yok_report"),
        permission: "undergraduatetransfer_table11byokreport",
    });

    return reportTypes;

}


